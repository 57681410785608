<template>
  <CFooter :fixed="false" style="background-color: #E5881E; color: #FFFFFF;">
    <div class="container text-center">
      <span class="ml-1">&copy;{{new Date().getFullYear()}} www.roselottokey.com</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
